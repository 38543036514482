<template>
  <div class="detail">
    <div v-if="activeProject.fields" class="grid-container">
      <!-- poster -->
      <div class="detail__poster">
        <div
          v-if="!showPlayer || showVideoInContent"
          class="img-wrapper"
          :class="{ 'img-wrapper--video': projectIsVideoProject && !showVideoInContent }"
          @click="playVideo('top')"
        >
          <img
            :src="activeProject.fields.poster.fields.file.url"
            :alt="activeProject.fields.poster.fields.file.url"
            class="img-fluid"
          />
        </div>
        <div
          v-if="projectIsVideoProject && !showVideoInContent"
          class="img-wrapper"
          :class="{ 'img-wrapper--invisible': !showPlayer }"
        >
          <jr-plyr ref="player">
            <div
              :data-plyr-provider="activeProject.fields.type"
              :data-plyr-embed-id="activeProject.fields.vimeoId"
            ></div>
          </jr-plyr>
        </div>
      </div>

      <!-- details -->
      <div class="detail__more" :class="{ active: moreActive }">
        <!-- description -->
        <div class="detail__title">{{ activeProject.fields.title }}</div>
        <div class="details">
          <ul class="detail__list">
            <li v-for="(object, index) in activeProject.fields.details" :key="index">
              <span v-for="(value, key) in object" :key="key">
                <span class="list_title">{{ key }}</span> :
                <span class="list__content">{{ value }}</span>
              </span>
            </li>
          </ul>
          <p class="detail__copy">{{ activeProject.fields.description }}</p>
        </div>

        <!-- video in content -->
        <div v-if="showVideoInContent && activeProject.fields.videoPosterImage" class="detail__poster">
          <div
            v-if="!showPlayer"
            class="img-wrapper content"
            :class="{ 'img-wrapper--video': projectIsVideoProject && showVideoInContent }"
            @click="playVideo('content')"
          >
            <img
              :src="activeProject.fields.videoPosterImage.fields.file.url"
              :alt="activeProject.fields.videoPosterImage.fields.file.url"
              class="img-fluid"
            />
          </div>
          <div v-show="showPlayer" v-if="projectIsVideoProject && showVideoInContent" class="img-wrapper content">
            <jr-plyr ref="player">
              <div
                :data-plyr-provider="activeProject.fields.type"
                :data-plyr-embed-id="activeProject.fields.vimeoId"
              ></div>
            </jr-plyr>
          </div>
        </div>

        <!-- additional content -->
        <div v-for="(image, index) in activeProject.fields.images" :key="index">
          <div
            v-if="activeProject.fields.images && activeProject.fields.images.length > 0"
            class="detail_image"
            :style="{
              'padding-bottom':
                (image.fields.file.details.image.height / image.fields.file.details.image.width) * 100 + '%',
            }"
          >
            <jr-lazy-image :alt="image.fields.file.url" class="img-fluid" :lazy-src="image.fields.file.url">
            </jr-lazy-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// compositions
import useProjects from '@/composition/useProducts';

// components
import { JrLazyImage, JrPlyr } from '@/components/';

export default {
  name: 'Project',

  components: {
    JrLazyImage,
    JrPlyr,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { getProjectById, activeProject } = useProjects();

    getProjectById(props.id);

    return {
      activeProject,
    };
  },

  data() {
    return {
      moreActive: false,
      showPlayer: false,
    };
  },

  computed: {
    player() {
      if (!this.$refs.player) return;

      return this.$refs.player.player;
    },

    projectIsVideoProject() {
      return this.activeProject.fields.type === 'vimeo' || this.activeProject.fields.type === 'youtube';
    },

    showVideoInContent() {
      return this.activeProject.fields.videoInContent;
    },
  },

  beforeUnmount() {
    this.showPlayer = false;
  },

  created() {
    // this lets the content fade in after 200ms
    setTimeout(() => {
      this.moreActive = true;
    }, 200);
  },

  methods: {
    playVideo(type) {
      if (!this.projectIsVideoProject) return;
      if (type === 'top' && this.showVideoInContent) return;

      this.showPlayer = true;
      this.player.play();
      // setTimeout(() => {
      //   this.player.play();
      // }, 2000);
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  color: #333;
}

// .detail__video {
//   /*margin-bottom: 60px;*/
//   /*padding: 2.5px;*/
// }

.detail_image {
  position: relative;
  margin: 0 0 35px;
  background: #efefef;

  .img-fluid {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .img-fluid[lazy='loaded'] {
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
}

.img-wrapper {
  height: 100%;
  padding: 2.5px;

  &.img-wrapper--invisible {
    // visibility: hidden;
    height: 0;
    padding: 0;
    overflow: hidden;
  }

  &.img-wrapper--video {
    position: relative;
    cursor: pointer;
  }

  &.img-wrapper--video::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    background: url('../../public/img/icons/play_circle_filled_white-48px.svg') no-repeat;
    background-size: contain;
  }
}

.img-wrapper.content {
  padding: 0;
}

.details {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: 1fr 1fr;
  margin: 30px 0;
}

.detail__title {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
  margin: 30px 0 20px;
}

.detail__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list_title {
  font-weight: bold;
}

.detail__copy {
  max-width: 600px;
  line-height: 130%;
  padding: 0;
  margin: 0;
}

.detail__more {
  opacity: 0;
  padding: 0 2.5px;

  .detail__poster {
    margin: 0 0 20px;
  }

  .detail_image {
    &:first-child {
      margin-top: 17.5px;
    }
  }
}

.detail__more.active {
  opacity: 1;
  transition: opacity 1s ease-out;
}

.detail__navigation {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  color: #333;
  margin-bottom: 25px;

  a {
    display: flex;
    color: #333;
    text-decoration: none;
    align-items: center;
  }
}

.icon__next {
  margin-left: 10px;
}

.icon__prev {
  margin-right: 10px;
}
</style>
